export const capitalize = (str) => {
  if (str) {
    str = str.split(" ");
    for (let i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
  }
};

export const hasPermission = (userPermissions, requiredPermission) => {
    return userPermissions.some((perm) => perm.name === requiredPermission);
};

export const shortNumFormat = (number) => {
    // Define the threshold for displaying in shorthand notation
    const threshold = 1000;

    // Check if the number is greater than or equal to the threshold
    if (number >= threshold) {
        // Calculate the appropriate multiplier (k for thousands, m for millions, etc.)
        const units = ['', 'k', 'm', 'b', 't'];

        // Calculate the index of the appropriate multiplier
        const multiplierIndex = Math.floor(Math.log10(number) / Math.log10(threshold));

        // Calculate the formatted value
        let formattedValue = (number / Math.pow(threshold, multiplierIndex)).toString();

        // Ensure two decimal places without rounding
        const decimalIndex = formattedValue.indexOf('.');
        if (decimalIndex !== -1) {
            formattedValue = formattedValue.substring(0, decimalIndex + 3);
        }

        // Return the formatted value with the multiplier
        return `${formattedValue}${units[multiplierIndex]}`;
    }

    // If the number is smaller than the threshold, return it as is
    return number;
};

export const formatNumberWithCommas = (value) => {
    if (typeof value === 'string') {
        value = parseFloat(value.replace(/,/g, ''));
        if (isNaN(value)) {
            throw new Error('Input string is not a valid number');
        }
    } else if (typeof value !== 'number') {
        throw new Error('Input must be a number or a string representing a number');
    }

    // Determine the number of decimal places to use
    const decimalPlaces = value % 1 === 0 ? 0 : 2;
    
    return value.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });
}

export const isEmpty = (obj) => obj == null || Object?.keys(obj).length === 0;

export const currency = (value) => new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
}).format(value);

export const deepEqual = (x, y) => {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty && x.constructor === y.constructor ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}

export const deepEqual2 = (obj1, obj2) => {
    // If both objects are not objects, compare them directly
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 === obj2;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If the number of keys is different, objects are not equal
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all keys in obj1 have the same value as in obj2 recursively
    for (const key of keys1) {
        if (!deepEqual2(obj1[key], obj2[key])) {
            return false;
        }
    }

    // If all checks passed, objects are equal
    return true;
}

export const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const generateUrl = (query, object) => {
    const transformedObject = Object.entries(object);
    let queryCopy = new URL(query);

    transformedObject.forEach(element => {
        if(element[1]=== ""){
            return
        } else {
            queryCopy.searchParams.append(element[0], element[1]);
        }
    });
    
    return queryCopy.href;
};
