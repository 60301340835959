import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "../helpers/utils";

const AuthRoutes = ({ component: PrivateComponent, requiredPermissionGroup, ...rest }) => {
    const auth = useSelector(state => state.auth);
    const loading = useSelector(state => state.loading);
    const role = useSelector(state => state.auth.user?.role);

    // Extract unique permission groups, ensuring role and permissions exist
    const permissionGroups = role?.permissions 
        ? [...new Set(role.permissions.map(permission => permission.group.name))] 
        : [];

    const isAuthenticated = localStorage.getItem("_m_a_t_");

    return ( 
        <Route
          {...rest}
          render={props => {
            if (!auth.isAuthenticated || !isAuthenticated) {
                return <Redirect to="/" />;
            }
            
            if (
              requiredPermissionGroup &&
              (!role || isEmpty(permissionGroups) || 
              !requiredPermissionGroup.some(group => permissionGroups.includes(group))) 
            ) {
                return <Redirect to="/403" />;
            }

            return <PrivateComponent {...props} isLoading={loading.isLoading} />;
          }}
        />
    );
}
 
export default AuthRoutes;
