import axios from 'axios';
import { toastError, toastSuccess } from '../../components/Toast';
import { unAuthpostApi, patchApi,postApi,putApi, getApi } from '../../utils/reqClient';
import {LOGIN_USER,LOGOUT_USER} from "../types/authTypes";
import {LOADING_TRUE,LOADING_FALSE} from "../types/loadingTypes";
import handleError from '../../utils/handleError';
import { GET_GROUPS } from '../types/adminTypes';

export function loginAdmin(userData,history) {
    return async (dispatch) => {
      try {
        dispatch({ type: LOADING_TRUE });
        const response = await postApi("admin/auth/login",userData);
        if (response.data.status) {
          toastSuccess("Login Successful")
          //localStorage.clear();
          const { token} = response.data.data;
          localStorage.setItem("_m_a_t_", token);
          
          let permissions = [];
          const response2 = await getApi(`admin/permissions/groups/`);
          if (response2.data.success === true) {
            const groups = response2.data.data || [];
            dispatch({ type: GET_GROUPS, payload: groups });
            if(groups?.length> 0) { 
              permissions = groups.flatMap(group => group.permissions.map(({ id, name }) => ({ id, name })));
            }
          }

          dispatch({ type: LOGIN_USER, payload: { user: response.data.data, permissions } });
          dispatch({ type: LOADING_FALSE });
          history.push("/dashboard");
        } else {
          dispatch({ type: LOADING_FALSE });
        }
      } catch (err) {
        dispatch({ type: LOADING_FALSE });
        handleError(err, dispatch);
      }
    };
  }
export function forgotPassword(userData,history) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_TRUE });
      const response = await patchApi("admin/auth/password",userData);
      const { success} = response.data;
      if (success) {
        dispatch({ type: LOADING_FALSE });
        toastSuccess('Password reset email sent successfully')
        history.push("/create-password");
      } else {
        dispatch({ type: LOADING_FALSE });
      }
    } catch (err) {
      dispatch({ type: LOADING_FALSE });
      handleError(err, dispatch);
    }
  };
}
export function createPassword(userData,history) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_TRUE });
      const response = await putApi("admin/auth/password",userData);
      const { success,message} = response.data;
      if (success) {
        dispatch({ type: LOADING_FALSE });
        toastSuccess(message)
        history.push("/");
      } else {
        dispatch({ type: LOADING_FALSE });
      }
    } catch (err) {
      dispatch({ type: LOADING_FALSE });
      handleError(err, dispatch);
    }
  };
}
export function createAdmin(userData,history,token) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_TRUE });
      const response = await unAuthpostApi(`admin/team?token=${token}`,userData);
      if (response.data.status) {
        toastSuccess("Account Created Successfully")
        dispatch({ type: LOADING_FALSE });
        history.push("/");
      } else {
        dispatch({ type: LOADING_FALSE });
      }
    } catch (err) {
      dispatch({ type: LOADING_FALSE });
      handleError(err, dispatch);
    }
  };
}

export const signOut = (env) => dispatch => {
  // Set global loading to false
  dispatch({ type: LOADING_FALSE });
  let storedEnv = env;
  if(!storedEnv){
    storedEnv = localStorage.getItem('selectedEnvironment');
  }
  localStorage.clear();
  dispatch({ type: LOGOUT_USER });
  if(storedEnv){
    localStorage.setItem('selectedEnvironment', storedEnv); 
    dispatch({ type: "ENV_CHANGE", payload: storedEnv });
    dispatch({
      type: "UPDATE_ENVIRONMENT",
      payload: storedEnv
    });
  }
};